module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Real Kitchen","short_name":"Real Kitchen","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"../contentcurator-gatsby-template/static/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6d824618db7f82eeb6f148c1ebd1742"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://realkitchen.com","stripQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-pinterest-twitter-facebook/gatsby-browser.js'),
      options: {"plugins":[],"delayTimer":1000,"pinterest":{"enable":true,"tall":true,"round":false},"twitter":{"enable":true,"containerSelector":null,"handle":null,"showFollowButton":true,"showTimeline":true,"showFollowerCount":true,"timelineTweetCount":1,"width":null,"height":null,"noHeader":true,"noFooter":true,"noBorders":true,"noScrollbar":true,"transparent":true},"facebook":{"enable":true,"containerSelector":null,"profile":null,"width":null,"height":null,"tabs":"timeline, events, messages","hideCover":false,"showFacepile":true,"smallHeader":false,"adaptContainerWidth":true}},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
